import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/templates/layout'
import Column2 from '../components/templates/column2'
import Header from '../components/organisms/header'
import Footer from '../components/organisms/footer'
import MainContent from '../components/organisms/main-content'
import Sidebar from '../components/organisms/sidebar'
import Card from '../components/molecules/card'
import Text from '../components/molecules/text'

export default () => {
  return (
    <Layout>
      <Helmet title={`ページが見つかりませんでした`} />
      <Header />
      <Column2>
        <MainContent>
          <Card>
            <Text>
              <h1>404 Not Found</h1>
              <p>
                お探しのページは削除されたか、移動されたた可能性があります。
                <br />
                URLに間違いがないか再度ご確認ください。
              </p>
              <p>
                <Link to="/">トップページに戻る</Link>
              </p>
            </Text>
          </Card>
        </MainContent>
        <Sidebar />
      </Column2>
      <Footer />
    </Layout>
  )
}
